<template>
  <v-container fluid class="pa-0">
    <ERow>
      <ECol cols="12" class="py-0">
        <ReportTable
          name="users"
          :headers="headers"
          :show-select="canDeleteUsers"
          :provider="getAllUsers"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :filter-fields="filterFields"
        >
          <template #actions-right>
            <v-btn
              v-if="canDeleteUsers"
              color="error"
              small
              :loading="isLoading"
              :disabled="!isDeleteUsersEnabled"
              @click="deleteAll"
            >
              Delete
            </v-btn>
          </template>
          <template #item.email="{ item }">
            <v-edit-dialog
              v-if="canEditUsers"
              large
              @save="updateEmail(item)"
              @open="newEmail = item.email"
            >
              {{ item.email }}
              <v-icon small color="primary"> fa-pencil-alt </v-icon>
              <template #input>
                <v-text-field
                  v-model="newEmail"
                  label="Email"
                  class="mt-4"
                  dense
                  hide-details
                />
              </template>
            </v-edit-dialog>
            <span v-else>{{ item.email }}</span>
          </template>
          <template #item.countryName="{ item }">
            <v-edit-dialog
              v-if="canEditUsers"
              large
              :return-value.sync="item.countryName"
              @save="updateCountry(item)"
            >
              {{ item.countryName }}
              <v-icon small color="primary"> fa-pencil-alt </v-icon>
              <template #input>
                <v-select
                  v-model="item.countryName"
                  :items="countries"
                  label="Country"
                  dense
                  class="mt-4"
                  item-value="iso3166_a2"
                  item-text="name"
                ></v-select>
              </template>
            </v-edit-dialog>
            <span v-else>{{ item.countryName }}</span>
          </template>
          <template #item.integrations="{ item }">
            {{ getConnectors(item) }}
          </template>
          <template #item.zohoId="{ item }">
            <OpenInZohoButton
              v-if="item.zohoId"
              :zoho-id="item.zohoId"
              type="user"
            />
          </template>
          <template #item.demo="{ item }">
            <OpenInNewWindowButton
              v-if="canViewDemo"
              :target-link="`/demos/users/${item.email}`"
              hint="Click to open as a demo"
              icon="fas fa-desktop"
            />
          </template>
          <template #item.fullname="{ item }">
            <span
              class="cursor-pointer primary--text"
              @click="openUserDialog(item.email)"
            >
              {{ item.fullname }}
            </span>
            <ImpersonateUserButton
              v-if="canImpersonateUsers"
              :email="item.email"
            />
          </template>
          <template #item.companyName="{ item }">
            <div class="d-flex">
              <span class="mr-1">
                <NuxtLink :to="`companies/${item.companyId}`">{{
                  item.companyName
                }}</NuxtLink>
              </span>
              <v-edit-dialog
                v-if="canEditUsers"
                large
                @save="linkCompany(item)"
                @open="initCompany(item)"
              >
                <v-icon v-if="item.companyName" small color="primary">
                  fa-pencil-alt
                </v-icon>
                <v-icon v-else small color="primary">far fa-plus-square</v-icon>
                <template #input>
                  <Autocomplete
                    v-model="selectedCompany"
                    :list-items="companiesItems"
                    resource="companies"
                    item-value="exid"
                    item-text="name"
                    label="Company"
                    :outlined="false"
                    :dense="false"
                    clearable
                  />
                </template>
              </v-edit-dialog>
            </div>
          </template>
          <template #item.persona="{ item }">
            <v-edit-dialog v-if="canEditUsers" large @save="savePersona(item)">
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" class="mr-1" v-on="on">{{
                    item.persona
                  }}</span>
                  <v-icon v-if="item.persona" small color="primary">
                    fa-pencil-alt
                  </v-icon>
                  <v-icon v-else small color="primary"
                    >far fa-plus-square</v-icon
                  >
                </template>
                <span>{{ getPersonaText(item.persona) }}</span>
              </v-tooltip>
              <template #input>
                <v-select
                  v-model="item.persona"
                  class="mt-3"
                  :items="personaItems"
                  label="Persona"
                  item-text="name"
                  item-value="value"
                ></v-select>
              </template>
            </v-edit-dialog>
            <div v-else>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" class="mr-1" v-on="on">{{
                    item.persona
                  }}</span>
                </template>
                <span>{{ getPersonaText(item.persona) }}</span>
              </v-tooltip>
            </div>
          </template>
          <template #item.lastEventName="{ item, header }">
            <ETruncatedDiv :width="header.width">
              {{ toTitleCase(item.lastEventName) }}
            </ETruncatedDiv>
          </template>
          <template #item.lastEventCreatedAt="{ item }">
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  {{ relativeDate(item.lastEventCreatedAt) }}
                </span>
              </template>
              <span>
                {{ formatDate(item.lastEventCreatedAt) }}
              </span>
            </v-tooltip>
          </template>
          <template #item.createdAt="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template #item.lastLoginAt="{ item }">
            {{ formatDate(item.lastLoginAt) }}
          </template>
          <template #item.telephone="{ item }">
            {{ item.telephone || "-" }}
          </template>
          <template #item.projects="{ item }">
            <EReadMore :content="item.projects || []" />
          </template>
          <template #item.referralUrl="{ item }">
            <span v-if="item.referralUrl">
              {{ getReferralText(item.referralUrl)[0] }}
              <b> shared </b>
              {{ getReferralText(item.referralUrl)[1] }}
            </span>
            <span v-else>-</span>
          </template>
          <template #item.features="{ item }">
            <EReadMore :content="getFeatureFlags(item)" :max-length="2" />
          </template>
        </ReportTable>
      </ECol>
    </ERow>
  </v-container>
</template>

<script lang="ts">
import headers from "@/components/users/usersHeaders"
import filterFields from "@/components/users/usersSearchFilters"
import countries from "@evercam/shared/constants/countries"
import { PersonaItems, UserAccessFeatures } from "@/components/constants"
import OpenInZohoButton from "@/components/OpenInZohoButton"
import OpenInNewWindowButton from "@/components/OpenInNewWindowButton"
import ImpersonateUserButton from "@/components/ImpersonateUserButton"
import Autocomplete from "@evercam/shared/components/Autocomplete"
import ReportTable from "@/components/ReportTable"
import Vue from "vue"
import { AdminApi } from "@evercam/shared/api/adminApi"
import { useUserStore } from "@/stores/users"
import { useReportStore } from "@/stores/report"
import { mapStores } from "pinia"
import { PERMISSIONS } from "@/constants/permissions"

export default Vue.extend({
  meta: {
    requiredPermissions: [PERMISSIONS.USERS.VIEW],
  },
  components: {
    ReportTable,
    OpenInZohoButton,
    OpenInNewWindowButton,
    Autocomplete,
    ImpersonateUserButton,
  },
  data: () => {
    return {
      headers: [],
      filterFields: {},
      newEmail: "",
      selectedCompany: null,
      companiesItems: [],
      countries: [],
      personaItems: PersonaItems,
      sortBy: "lastEventCreatedAt",
      sortDesc: true,
      isLoading: false,
      isDeletionFailed: false,
      PERMISSIONS,
    }
  },
  head() {
    return {
      title: "Admin - Users",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useReportStore),
    isDeleteUsersEnabled() {
      return !!this.reportStore?.selectedItems?.length
    },
    getAllUsers() {
      return AdminApi.users.getUsers
    },
    canDeleteUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.DELETE)
    },
    canEditUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.UPDATE)
    },
    canImpersonateUsers() {
      return this.$permissions.user.can(PERMISSIONS.USERS.IMPERSONATE)
    },
    canViewDemo() {
      return this.$permissions.user.can(PERMISSIONS.DEMOS.VIEW)
    },
  },
  created() {
    this.headers = headers(this).filter(
      (header) => this.canViewDemo || header.value !== "demo"
    )
    this.countries = countries
    this.filterFields = filterFields(this)
  },
  methods: {
    openUserDialog(email) {
      useUserStore().openDialog(email)
    },
    getCountryCode(country) {
      let foundCountry = this.countries.filter((data) => {
        return data.name == country
      })

      return foundCountry[0].value.toLowerCase()
    },
    async updateEmail(item) {
      let params = {
        email: this.newEmail,
      }
      this.updateUser(item.id, params)
      item.email = this.newEmail
    },
    updateCountry(item) {
      let params = {
        country: this.getCountryCode(item.countryName),
      }
      this.updateUser(item.id, params)
    },
    updateUser(id, params) {
      AdminApi.users
        .updateUser(id, params)
        .then(() => {
          this.$notifications.success("User modified successfully.")
        })
        .catch((error) => {
          this.$notifications.error({ text: "Failed to update user!", error })
        })
    },
    initCompany(item) {
      this.selectedCompany = {
        exid: item.companyExid,
        name: item.companyName,
      }
      this.companiesItems = [this.selectedCompany]
    },
    linkCompany(item) {
      item.companyName = this.selectedCompany?.name
      item.companyId = this.selectedCompany?.id
      let params = {
        companyId: item.companyId,
      }
      this.updateUser(item.id, params)
    },
    savePersona(item) {
      let params = {
        persona: item.persona,
      }
      this.updateUser(item.id, params)
    },
    getPersonaText(value) {
      return (
        this.personaItems.find((item) => item.value === value)?.name || "Other"
      )
    },
    getFeatureFlags(item) {
      return item.features.map(
        (feature) =>
          UserAccessFeatures.find((item) => item.value === feature)?.name
      )
    },
    getReferralText(str) {
      return str?.split(" shared ")
    },
    getConnectors({ integrations: connectors }) {
      return (
        connectors
          ?.map((connector) => this.toTitleCase(connector))
          ?.join(", ") || "-"
      )
    },
    async deleteAll() {
      if (
        await this.$confirmDialog.open({
          title: "Delete User(s) ?",
          message: "Are you sure you want to delete selected user(s)!",
        })
      ) {
        this.isLoading = true
        for (const user of this.reportStore.selectedItems) {
          await this.deleteUser(user.email)
        }
        this.isLoading = false
        this.reportStore.selectedItems = []
        if (this.isDeletionFailed) {
          this.isDeletionFailed = false

          return
        }
        this.$notifications.success("User(s) deleted successfully.")
      }
    },
    async deleteUser(email) {
      try {
        await AdminApi.users.deleteUser(email, { verifEmail: email })
        this.reportStore.items = this.reportStore.items.filter(
          (item) => item.email !== email
        )
      } catch (error) {
        this.isDeletionFailed = true
        this.$notifications.error({ text: "Delete User failed!", error })
      }
    },
  },
})
</script>
